import { selectPlan, selectRole } from "redux/auth/selectors";
import { useAppSelector } from "redux/hooks";
import { getMinimumRequiredPlan, hasAccessTo } from "utilities/featureFlags";
import { isKCRole } from "utilities/type-helpers/userRoles";

export const useFeatureFlags = () => {
  const currentRole = useAppSelector(selectRole);
  const currentPlan = useAppSelector(selectPlan);

  const isCasasoftRole = isKCRole(currentRole);

  return {
    currentRole,
    isCasasoftRole,
    currentPlan,
    hasAccessTo,
    getMinimumRequiredPlan,
  };
};
